export const vidSrcMap = [
  '/01_IMG0525.mp4',
  '/02_IMG0641.mp4',
  '/03_IMG0359.mp4',
  '/04_IMG0417.mp4',
  '/05_IMG0428.mp4',
  '/06_IMG0442.mp4',
  '/07_IMG0485.mp4',
  '/08_IMG0540.mp4',
  '/09_IMG0541.mp4',
  '/10_IMG0543.mp4',
  '/11_IMG0655.mp4',
  '/12_IMG0817.mp4',
  '/13_IMG0825.mp4',
  '/13_IMG0825.mp4',
  '/14_IMG1450.mp4',
  '/15_IMG1451.mp4',
  '/16_IMG1452.mp4',
  '/17_IMG1460.mp4',
  '/18_IMG1461.mp4',
  '/19_IMG1462.mp4',
  '/20_IMG1463.mp4',
  '/21_IMG1465.mp4',
  '/22_IMG1475.mp4',
  '/23_IMG1476.mp4',
  ]
;

export default vidSrcMap; 